<template>
  <div class="home">
    <div id="preview">
      <div class="container">
        <div class="section-header">
          <div class="title">轻快好用的 Web 控制面板</div>
          <div class="description">
            描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述
          </div>
        </div>
        <div class="preview-wrapper">
          <div class="operations">
            <a href="javascript:;" class="previous" @click="swipePrev">
              <i class="icon ion-ios-arrow-back" />
            </a>
            <a href="javascript:;" class="next" @click="swipeNext">
              <i class="icon ion-ios-arrow-forward" />
            </a>
          </div>
          <div class="frame">
            <img src="~@/assets/img/ipad.png" alt="macbook" class="background" />
            <swipe class="preview" ref="swipe">
              <swipe-item class="preview-item">
                <img src="~@/assets/img/preview.png" alt="preview" />
              </swipe-item>
              <swipe-item class="preview-item">
                <img src="~@/assets/img/preview.png" alt="preview" />
              </swipe-item>
              <swipe-item class="preview-item">
                <img src="~@/assets/img/preview.png" alt="preview" />
              </swipe-item>
            </swipe>
          </div>
        </div>
      </div>
    </div>
    <div id="feature">
      <div class="container">
        <div class="section-header">
          <div class="title">我们的优势</div>
          <div class="description">深度客制化的控制面板 / 客户中心，使得交互界面得心应手、速度非凡</div>
        </div>
        <div class="row">
          <div class="col" v-for="(item, index) in display.features" :key="index">
            <div class="feature">
              <img :src="item.icon" alt="icon" class="icon" />
              <div class="title">{{ item.title }}</div>
              <div class="description">{{ item.description }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="installation">
      <div class="container">
        <div class="section-header">
          <div class="title">即刻安装</div>
          <div class="description">您仅需使用一句命令即可快捷安装至您的 Linux 服务器中</div>
        </div>
        <div class="terminal">
          <div class="header">
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div class="console">
            {{ display.command }}

            <a href="javascript:;" class="copy" v-clipboard:copy="display.command"> 复制 </a>
          </div>
        </div>
      </div>
    </div>
    <div id="partners">
      <div class="container">
        <div class="section-header">
          <div class="title">合作伙伴</div>
          <div class="description">申请 IDC 合作定制版请通过官方渠道达成联系</div>
        </div>
        <div class="row">
          <div class="col-3" v-for="(item, index) in display.partners" :key="index">
            <div class="partner">
              <img :src="item.icon" :alt="item.title" class="icon" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
require("vue-swipe/dist/vue-swipe.css")
import { Swipe, SwipeItem } from "vue-swipe"

export default {
  name: "Home",
  data() {
    return {
      display: {
        command: 'sh -c "$(curl -fsSL https://www.changchengyundun.com/innstall.sh)"',
        features: [
          {
            icon: require("@/assets/img/icons/console.svg"),
            title: "快捷安装",
            description: "描述描述描述描述描述描述描述描述描述描述描述描述",
          },
          {
            icon: require("@/assets/img/icons/security.svg"),
            title: "安全防控",
            description: "描述描述描述描述描述描述描述描述描述描述描述描述",
          },
          {
            icon: require("@/assets/img/icons/fast.svg"),
            title: "快速高效",
            description: "描述描述描述描述描述描述描述描述描述描述描述描述",
          },
          {
            icon: require("@/assets/img/icons/extension.svg"),
            title: "扩展应用",
            description: "描述描述描述描述描述描述描述描述描述描述描述描述",
          },
        ],
        partners: [
          {
            icon: require("@/assets/img/partners/aliyun.png"),
            title: "阿里云",
          },
          {
            icon: require("@/assets/img/partners/qcloud.png"),
            title: "腾讯云",
          },
          {
            icon: require("@/assets/img/partners/west.png"),
            title: "西部数码",
          },
          {
            icon: require("@/assets/img/partners/dnscom.png"),
            title: "DNSCOM",
          },
        ],
      },
    }
  },
  methods: {
    swipePrev() {
      this.$refs.swipe.prev()
    },
    swipeNext() {
      this.$refs.swipe.next()
    },
  },
  components: {
    Swipe,
    SwipeItem,
  },
}
</script>

<style lang="scss">
@import "~@/assets/scss/init";
@import "~@/assets/scss/layouts/base";

.home {
  //

  .section-header {
    padding: 0 15vw;
    text-align: center;

    .title {
      font: {
        size: 2.5rem;
        weight: bolder;
      }
      color: darken($fontColor, 15);
      margin-bottom: $space;
    }

    .description {
      color: lighten($fontColor, 15);
      margin-bottom: $space * 3;
    }
  }

  #preview {
    padding: 3.5vw 0;
    min-height: calc(100vh - #{$navbarHeight});

    .preview-wrapper {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      .operations {
        $size: 5rem;

        font: {
          size: $size;
          weight: bolder;
        }

        a {
          color: darken(map-get($themeColors, "light"), 2.5);

          @include commonHoverAndActive;

          &.previous,
          &.next {
            position: absolute;
            top: calc(50% - #{$size});
          }

          &.previous {
            left: 0;
          }

          &.next {
            right: 0;
          }
        }
      }

      .frame {
        position: relative;
        width: 45vw;

        .background {
          width: 100%;
        }

        .preview {
          $topAndBottom: 5%;
          $rightAndLeft: 3.25%;

          position: absolute;
          top: $topAndBottom - 0.25%;
          right: $rightAndLeft;
          bottom: $topAndBottom;
          left: $rightAndLeft;
          height: auto;
          border-radius: $borderRadius * 3.5;

          .preview-item,
          img {
            width: 100%;
            height: 100%;
            border-radius: $borderRadius * 3.5;
          }
        }
      }
    }
  }

  #feature {
    padding: 3.5vw 0;
    background: $backgroundColor;

    .feature {
      padding: $space * 2;
      text-align: center;
      background: white;
      border-radius: $borderRadius;

      @include boxShadowBorder;

      .icon {
        height: $space * 5.5;
        margin-bottom: $space / 2;
      }

      .title {
        font: {
          size: 1.25rem;
          weight: bolder;
        }
        color: darken($fontColor, 15);
        margin-bottom: $space;
      }

      .description {
        color: lighten($fontColor, 15);
      }
    }
  }

  #installation {
    padding: 3.5vw 0;

    .terminal {
      margin: 0 auto;
      width: 60vw;
      border-radius: $borderRadius;
      border: $borderSize solid darken(map-get($themeColors, "light"), 5);

      .header {
        padding: ($space / 2) $space;
        border-bottom: $borderSize solid darken(map-get($themeColors, "light"), 5);

        span {
          $size: $space * 0.75;

          display: inline-block;
          width: $size;
          height: $size;
          border-radius: $size;
          margin-right: $space / 2;
          background: map-get($themeColors, "light");

          &:first-child {
            background: map-get($themeColors, "danger");
          }

          &:nth-child(2) {
            background: map-get($themeColors, "success");
          }

          &:last-child {
            background: map-get($themeColors, "warning");
          }
        }
      }

      .console {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: $space;
        font-weight: bolder;
        color: darken($fontColor, 15);

        .copy {
          color: white;
          font-size: 0.75rem;
          padding: ($space / 4) $space;
          background: map-get($themeColors, "dark");
          border-radius: $borderRadius;

          @include commonHoverAndActive;
        }
      }
    }
  }

  #partners {
    padding: 3.5vw 0;
    background: $backgroundColor;

    .partner {
      display: flex;
      align-items: center;
      justify-content: center;
      background: white;
      height: $space * 5;
      border-radius: $borderRadius;
      margin-bottom: $space;

      @include boxShadowBorder;

      img {
        height: 50%;
      }
    }
  }
}
</style>
